var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline" } },
    [
      _vm.showDevInfo ? _c("stageline-dev-info") : _vm._e(),
      _vm.loaded
        ? [
            _vm.showAdminTools
              ? _c("stageline-admin-tools", {
                  on: {
                    "next-step": function ($event) {
                      return _vm.$refs.slideViewer.next()
                    },
                  },
                })
              : _vm._e(),
            !_vm.stagelineConfig?.hide_sidebar
              ? _c(
                  "div",
                  { attrs: { id: "stageline-sidebar-container" } },
                  [_c("stageline-sidebar", { ref: "stageline-sidebar" })],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "client-feedback-sidebar-position" },
              [_c("client-feedback-sidebar")],
              1
            ),
            _c(
              "div",
              { attrs: { id: "stageline-content-container" } },
              [
                _vm.stagelineLoaded
                  ? [
                      !_vm.stagelineConfig?.hide_navigation
                        ? _c("stageline-navigation")
                        : _c("div", { staticClass: "mt-5" }),
                      _c(
                        "div",
                        {
                          class: { "wide-slide": _vm.wideSlide },
                          attrs: { id: "stageline-container" },
                        },
                        [
                          _c("slide-viewer", {
                            ref: "slideViewer",
                            on: {
                              "show-contact-modal": _vm.showContactModal,
                              collapseSidebar: _vm.collapseSidebar,
                            },
                          }),
                          !_vm.filteredPeriodSlideList(_vm.currentPeriodName)
                            .length
                            ? _c("div", { staticClass: "pt-3" }, [
                                _c("h3", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n              Check back soon for step by step guidance for forming,\n              managing and maintaining your company in " +
                                      _vm._s(
                                        _vm.jurisdiction.state_province_region
                                      ) +
                                      "\n              on your personalized Stageline.\n            "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("contact-modal", {
                            ref: "contact-modal",
                            attrs: { title: "Add a contact", type: "add" },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _c("ct-centered-spinner", { staticClass: "mt-5" }, [
                      _vm._v("\n        Loading Stageline...\n      "),
                    ]),
              ],
              2
            ),
          ]
        : _c("ct-centered-spinner", { staticClass: "mt-5" }, [
            _vm._v("\n    Loading Stageline...\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }