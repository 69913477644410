<template>
  <div id="stageline">
    <stageline-dev-info v-if="showDevInfo" />

    <template v-if="loaded">
      <stageline-admin-tools
        v-if="showAdminTools"
        @next-step="$refs.slideViewer.next()"
      />

      <div
        v-if="!stagelineConfig?.hide_sidebar"
        id="stageline-sidebar-container"
      >
        <stageline-sidebar ref="stageline-sidebar" />
      </div>

      <div class="client-feedback-sidebar-position">
        <client-feedback-sidebar />
      </div>

      <div id="stageline-content-container">
        <template v-if="stagelineLoaded">
          <stageline-navigation v-if="!stagelineConfig?.hide_navigation" />
          <div v-else class="mt-5" />

          <div
            id="stageline-container"
            :class="{ 'wide-slide': wideSlide }"
          >
            <slide-viewer
              ref="slideViewer"
              @show-contact-modal="showContactModal"
              @collapseSidebar="collapseSidebar"
            />
            <div
              v-if="!filteredPeriodSlideList(currentPeriodName).length"
              class="pt-3"
            >
              <h3 class="text-center">
                Check back soon for step by step guidance for forming,
                managing and maintaining your company in {{ jurisdiction.state_province_region }}
                on your personalized Stageline.
              </h3>
            </div>

            <contact-modal
              ref="contact-modal"
              :title="'Add a contact'"
              :type="'add'"
            />
          </div>
        </template>
        <ct-centered-spinner v-else class="mt-5">
          Loading Stageline...
        </ct-centered-spinner>
      </div>
    </template>
    <ct-centered-spinner v-else class="mt-5">
      Loading Stageline...
    </ct-centered-spinner>
  </div>
</template>

<script>
/* Responsibilities and Notes:
This is where any entry logic, including stageline routes, should happen when users enter stageline,
regardless of where they came from (decision tree, URL, etc.). If coming from SelectStep or
SelectCompanyStartingPoint, the route params will be passed in from those components. */

import { mapActions, mapGetters } from 'vuex'
import { setTimeout } from 'timers'
import _ from 'lodash'
import corporateTransparencyAct from '@/store/modules/lazyLoaded/stageline/phases/corporateTransparencyAct.store'
import { setStagelineJurisdiction } from '@/components/StagelineV2/helper.js'

export default {
  name: 'Stageline',
  components: {
    StagelineDevInfo:       () => import('@/components/StagelineV2/shared/StagelineDevInfo'),
    StagelineAdminTools:    () => import('@/components/StagelineV2/shared/StagelineAdminTools'),
    StagelineNavigation:    () => import('@/components/StagelineV2/StagelineNavigation'),
    StagelineSidebar:       () => import('@/components/StagelineV2/StagelineSidebar'),
    ClientFeedbackSidebar:  () => import('@/components/StagelineV2/clientFeedback/ClientFeedbackSidebar'),
    SlideViewer:            () => import('@/components/StagelineV2/SlideViewer'),
    ContactModal:           () => import('@/components/ContactModal'),
    CtCenteredSpinner:      () => import('@/components/shared/CtCenteredSpinner'),
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'connectedToProduction',
    ]),
    ...mapGetters('stageline', [
      'captureMonsterCompanyNeedsFormDataProcessed',
      'company',
      'companyStagelineRoute',
      'currentPeriod',
      'currentSlide',
      'currentPeriodName',
      'filteredPeriodSlideList',
      'firstIncompleteSlideId',
      'jurisdiction',
      'periodDeepLoaded',
      'periods',
      'stagelineConfig',
      'stagelineJurisdiction',
      'stagelineLoaded',
      'showAdminTools',
      'isCtaPeriod',
    ]),
    ...mapGetters('companies', [
      'hasExistingRAServiceInJurisdiction',
    ]),
    wideSlide() {
      const isDocumentSlide = this.currentSlide?.layout_type === 'document'
      const isVerifyCheckoutSlide = this.currentSlide?.layout_type === 'verifyCheckout'
      const filingOptionsSlideHasManyProducts = this.currentSlide?.layout_type === 'filingOptions'
        && this.currentSlide?.products.length > 3
        && !this.hasExistingRAServiceInJurisdiction(this.company.domestic_registration?.jurisdiction)
      return isDocumentSlide || isVerifyCheckoutSlide || filingOptionsSlideHasManyProducts
    },
    showDevInfo() {
      return !this.connectedToProduction
    },
  },
  async mounted() {
    // Set local variables -------------------------------------------------------------------------
    const params      = this.$route.params
    const companyId   = params.companyId
    const slideId     = params.slideId

    // TODO check this works
    await this.refreshCompaniesRequiringCtaIds()

    if (companyId) {
      // todo maybe here we want to "preserveStageline" if the currentPeriodName === CTA
      await this.setCurrentCompany({ id: companyId })
    } else {
      await this.$router.push({ name: 'root' })
    }

    await this.initializeStageline()
    const stagelineRoute   = this.companyStagelineRoute
    let lastVisitedSlide = _.mapKeys(this.company?.stageline?.last_visited_slide, (v, k) => _.camelCase(k))
    if (!this.periodDeepLoaded(this.currentPeriodName)) await this.loadPeriod(this.currentPeriodName)

    const promises = []

    if (this.currentPeriodName === 'start') {
      // Load Hire Us Context for Formation Period -------------------------------------------------
      promises.concat([
        this.setEinEligibilityStatus(companyId),
        this.setSCorpEligibilityStatus(companyId),
        this.setupCheckoutContext({ companyId: companyId }),
        // Needed for product upsells that include RA. Could optimize to load at a jurisdiction level
        this.loadRAProducts(),
        this.loadSimpleProducts(),
        this.loadProductBundles(),
        this.loadSupplyAndConsultProducts(),
        this.fetchMostRecentDomainAndEmail(companyId),
        this.fetchHostingTransferRequest({ companyId }),
        this.fetchDomainTransferRequests(companyId),
        this.fetchStandaloneHostingRequest({ companyId }),
        this.fetchRegistrations({ companyId }),
        // Stageline Client Feedback Tool
        this.setClientFeedbackOptions(),
        this.setClientFeedbackSources(),
        this.setAllClientFeedbackByCompanyId(),
      ])

      if (this.currentPeriod?.id !== lastVisitedSlide?.periodId) lastVisitedSlide = null
    } else if (this.isCtaPeriod) {
      this.$store.registerModule('corporateTransparencyAct', corporateTransparencyAct)
    }

    // Speed things up by 20% by grouping async calls
    await Promise.all(promises)

    // Process CompanyAgencyResource if came from capture monster ----------------------------------
    if (this.captureMonsterCompanyNeedsFormDataProcessed) await this.processCaptureMonsterFormData()

    // Stageline Setup -----------------------------------------------------------------------------
    setStagelineJurisdiction()
    this.setGhostMode(this.connectedToProduction && this.showAdminTools)

    // Stageline Client Feedback Tool
    this.setClientFeedbackClientData()
    this.setAllClientFeedbackForEndOfStage()

    // Navigate to Hire Us if no navigable slides
    if (!this.filteredPeriodSlideList(this.currentPeriodName).length) await this.navigateToHireUs()

    // Navigate to Stageline -----------------------------------------------------------------------
    if (this.currentPeriod && slideId) {
      // Case 1: Use period and slide id from SelectStep, SelectCompanyStartingPoint, or RouteParams
      await this.fetchSlideInPeriod({
        companyId,
        periodId: this.currentPeriod.id,
        slideId,
      })
    } else if (this.$route.name === 'stageline-v2-stage' && params?.stageIdOrName) {
      // Case 2: Else use stageid or stage name
      await this.goToStage(params.stageIdOrName)
    } else if (stagelineRoute) {
      // Case 3: Else use stageline route if available
      await this.goToStagelineRoute(stagelineRoute)
    } else if (!_.isEmpty(lastVisitedSlide))   {
      // Case 4: Else use last visited slide if available
      await this.fetchSlideInPeriod({
        companyId,
        ...lastVisitedSlide,
      })
    } else {
      // Case 5: Else go to first incomplete slideId
      this.goToSlide(this.firstIncompleteSlideId({
        type: 'period',
        typeId: this.currentPeriod.id,
      }))
    }

    // Auto-complete necessary stages/steps/slides on frontend -------------------------------------
    // Do this regardless of how the current slide was accessed
    // Only for LLC and Corp
    this.completeStagesStepsAndSlides()
    if (this.$route.path?.includes('/slide/') && slideId) await this.completeStageUntilSlide(slideId)
    this.loaded = true
  },

  async updated() {
    while (this.company == null) {
      await new Promise(r => setTimeout(r, 200))
    }
    setStagelineJurisdiction()
  },
  methods: {
    ...mapActions('stageline', [
      'completeStagesStepsAndSlides',
      'loadPeriod',
      'fetchSlideInPeriod',
      'goToSlide',
      'goToStagelineRoute',
      'setGhostMode',
      'completeStageUntilSlide',
      'goToStage',
      'initializeStageline',
    ]),
    ...mapActions('stagelineSchemaForm', [
      'processCaptureMonsterFormData',
    ]),
    ...mapActions('checkout', [
      'loadRAProducts',
      'loadSimpleProducts',
      'loadProductBundles',
      'loadSupplyAndConsultProducts',
    ]),
    ...mapActions('checkout', {
      setupCheckoutContext: 'setupContext',
    }),
    ...mapActions('companies', [
      'setCurrentCompany',
      'fetchRegistrations',
    ]),
    ...mapActions('dashpanel', [
      'refreshCompaniesRequiringCtaIds',
    ]),
    ...mapActions('orderItems', [
      'setEinEligibilityStatus',
      'setSCorpEligibilityStatus',
    ]),
    ...mapActions('domains', [
      'fetchMostRecentDomainAndEmail',
      'fetchHostingTransferRequest',
      'fetchDomainTransferRequests',
      'fetchStandaloneHostingRequest',
    ]),
    ...mapActions('stagelineClientFeedback', [
      'setClientFeedbackOptions',
      'setClientFeedbackSources',
      'setClientFeedbackClientData',
      'setAllClientFeedbackByCompanyId',
      'setAllClientFeedbackForEndOfStage',
    ]),
    showContactModal() {
      this.$refs['contact-modal'].show()
    },
    collapseSidebar() {
      this.$refs['stageline-sidebar'].collapseSidebar()
    },
    async navigateToHireUs() {
      await this.$router.push({
        name: 'choose-category',
        params: { companyId: this.company.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#stageline {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  #stageline-sidebar-container {
    height: 100%;
  }

  #stageline-content-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    #stageline-container {
      height: 100%;
      width: 100%;
      max-width: 1040px;
      margin: 0 auto;
      padding: 0 2.5em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.wide-slide {
        justify-content: center;
        max-width: unset !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #stageline {
    width: 100% !important;

    #stageline-content-container {
      width: 100% !important;
      max-width: unset;
      margin: 0 !important;

      #stageline-container {
        width: 100% !important;
        padding: 0 2.0em;
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #stageline {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;

    .client-feedback-sidebar-position {
      display: none;
    }

    #stageline-sidebar-container {
      display: none;
    }

    #stageline-content-container {
      padding-top: 1.4em;

      #stageline-container {
        padding: 0 1.0em;
      }
    }
  }
}

::v-deep .btn {
  border-radius: $stageline-primary-border-radius !important;
}
</style>
